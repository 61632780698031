.wrapper .content-wrapper .content-header h1 font {
  vertical-align: inherit;
}
.wrapper .pickr .pcr-button {
  width: 100%;
}
.wrapper .color-box {
  width: 40px;
  height: 15px;
  display: inline-block;
}
.wrapper .calendar {
  background-color: white;
}
.wrapper .calendar.mini {
  background-color: white;
}

.wrapper-list .card .card-header {
  padding: 7px;
}
.wrapper-list .card .card-header .card-header-left {
  float: left;
}
.wrapper-list .card .card-header .card-header-left button {
  height: 30px;
  font-size: 12px;
}
.wrapper-list .card .card-header .card-header-right {
  float: right;
}
.wrapper-list .card .card-header .card-header-right ul {
  list-style-type: none;
  margin: 0;
  width: 100%;
}
.wrapper-list .card .card-header .card-header-right ul li {
  float: left;
  margin-right: 3px;
}
.wrapper-list .card .card-header .card-header-right ul li select {
  height: 30px;
  line-height: 30px;
  max-width: 200px;
  font-size: 13px;
}
.wrapper-list .card .card-header .card-header-right ul li select optgroup {
  font-size: 13px;
}
.wrapper-list .card .card-header .card-header-right ul li input {
  height: 30px;
  line-height: 30px;
}
.wrapper-list .card .card-header .card-header-right ul li .input-group {
  float: right;
  margin-top: 0px;
}
.wrapper-list .card .card-header .card-header-right ul li .input-group .input-group-append {
  height: 30px;
  line-height: 30px;
}
.wrapper-list .card .card-body table thead {
  font-size: 14px;
}
.wrapper-list .card .card-body table thead tr :first-child {
  vertical-align: middle !important;
}
.wrapper-list .card .card-body table thead th {
  padding: 5px !important;
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.wrapper-list .card .card-body table thead th .fa-sort {
  cursor: pointer;
}
.wrapper-list .card .card-body table tbody {
  font-size: 13px;
}
.wrapper-list .card .card-body table tbody tr :first-child {
  vertical-align: middle !important;
}
.wrapper-list .card .card-body table tbody tr td {
  padding: 5px !important;
}
.wrapper-list .card .card-body table tbody tr td button {
  font-size: 10px;
}
.wrapper-list .card .card-footer {
  padding: 7px;
}
.wrapper-list .card .card-footer .pagination {
  font-size: 13px;
  margin: 0;
}

.wrapper-view .card .card-header {
  padding: 7px;
}
.wrapper-view .card .card-header .card-header-left {
  float: left;
}
.wrapper-view .card .card-header .card-header-left button {
  height: 30px;
  font-size: 12px;
}
.wrapper-view .card .card-header .card-header-right {
  float: right;
}
.wrapper-view .card .card-header .card-header-right ul {
  list-style-type: none;
  margin: 0;
  width: 100%;
}
.wrapper-view .card .card-header .card-header-right ul li {
  float: left;
  margin-right: 3px;
}
.wrapper-view .card .card-header .card-header-right ul li select {
  height: 30px;
  line-height: 30px;
  max-width: 200px;
  font-size: 13px;
}
.wrapper-view .card .card-header .card-header-right ul li select optgroup {
  font-size: 13px;
}
.wrapper-view .card .card-header .card-header-right ul li input {
  height: 30px;
  line-height: 30px;
}
.wrapper-view .card .card-header .card-header-right ul li .input-group {
  float: right;
  margin-top: 0px;
}
.wrapper-view .card .card-header .card-header-right ul li .input-group .input-group-append {
  height: 30px;
  line-height: 30px;
}
.wrapper-view .card .card-body .form-group {
  margin-bottom: 4px;
}
.wrapper-view .card .card-body .form-group ul {
  list-style-type: none;
  margin: 0;
  width: 100%;
}
.wrapper-view .card .card-body .form-group ul {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 auto;
}
.wrapper-view .card .card-body .form-group ul li {
  display: inline-block;
  margin-bottom: 4px;
}
.wrapper-view .card .card-body .form-group ul li img {
  max-width: 200px;
  height: 200px;
  cursor: pointer;
}
.wrapper-view .card .card-body .form-group .card-header {
  font-size: 14px;
}
.wrapper-view .card .card-body .form-group .card-header .btn-group :first-child {
  width: 300px;
}
.wrapper-view .card .card-body .form-group table thead {
  font-size: 14px;
}
.wrapper-view .card .card-body .form-group table thead tr :first-child {
  vertical-align: middle !important;
}
.wrapper-view .card .card-body .form-group table thead th {
  padding: 5px !important;
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.wrapper-view .card .card-body .form-group table thead th .fa-sort {
  cursor: pointer;
}
.wrapper-view .card .card-body .form-group table tbody {
  font-size: 13px;
}
.wrapper-view .card .card-body .form-group table tbody tr :first-child {
  vertical-align: middle !important;
}
.wrapper-view .card .card-body .form-group table tbody tr td {
  padding: 5px !important;
}
.wrapper-view .card .card-body .form-group table tbody tr td button {
  font-size: 10px;
}
.wrapper-view .card .card-footer {
  padding: 7px;
}
.wrapper-view .card .card-footer .pagination {
  font-size: 13px;
  margin: 0;
}

div.modal .modal-body {
  overflow: auto;
}