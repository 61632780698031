@mixin base-table() {
    table {
        thead {
            font-size: 14px;
            tr {
                :first-child {
                    vertical-align: middle!important;
                }
            }
            th {
                padding: 5px !important;
                color: #495057;
                background-color: #e9ecef;
                border-color: #dee2e6;
                .fa-sort {
                    cursor: pointer;
                }
            }
        }
        tbody {
            font-size: 13px;
            tr {
                :first-child {
                    vertical-align: middle!important;
                }
            }
            tr {
                td {
                    padding: 5px !important;
                    button {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
@mixin base-ul {
    ul {
        list-style-type: none;
        margin: 0;
        width: 100%;
    }
}

.wrapper {
    .content-wrapper {
        .content-header {
            h1 {
                font {
                    vertical-align: inherit;
                }
            }
        }
    }
    .pickr .pcr-button {width:100%}
    .color-box {width: 40px;height: 15px;display:inline-block;}
    .calendar{background-color: white;}
    .calendar.mini{background-color: white;}
}
.wrapper-list {
    .card {
        .card-header {
            padding: 7px;
            .card-header-left {
                float: left;
                button {
                    height: 30px;
                    font-size: 12px;
                }
            }
            .card-header-right {
                float: right;
                @include base-ul();
                ul li {
                    float: left;
                    margin-right: 3px;
                    select {
                        height: 30px;
                        line-height: 30px;
                        max-width: 200px;
                        font-size: 13px;
                        optgroup {
                            font-size: 13px;
                        }
                    }
                    input {
                        height: 30px;
                        line-height: 30px;
                    }
                    .input-group {
                        float: right;
                        margin-top: 0px;
                        .input-group-append {
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        .card-body {
            @include base-table();
        }
        .card-footer {
            padding: 7px;
            .pagination {
                font-size: 13px;
                margin: 0;
            }
        }
    }
}
.wrapper-view {
    .card {
        .card-header {
            padding: 7px;
            .card-header-left {
                float: left;
                button {
                    height: 30px;
                    font-size: 12px;
                }
            }
            .card-header-right {
                float: right;
                @include base-ul();
                ul li {
                    float: left;
                    margin-right: 3px;
                    select {
                        height: 30px;
                        line-height: 30px;
                        max-width: 200px;
                        font-size: 13px;
                        optgroup {
                            font-size: 13px;
                        }
                    }
                    input {
                        height: 30px;
                        line-height: 30px;
                    }
                    .input-group {
                        float: right;
                        margin-top: 0px;
                        .input-group-append {
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        .card-body {
            .form-group {
                margin-bottom: 4px;
                @include base-ul();
                ul {
                    width:100%; 
                    overflow-x:auto; 
                    white-space:nowrap; 
                    margin:0 auto;
                    li {
                        display:inline-block;
                        margin-bottom: 4px;
                        img {
                            max-width: 200px;
                            height: 200px;
                            cursor: pointer;
                        }
                    }
                }
                .card-header {
                    font-size: 14px;
                    .btn-group {
                        :first-child {
                            width: 300px;
                        }
                    }
                }
                @include base-table();
            }
        }
        .card-footer {
            padding: 7px;
            .pagination {
                font-size: 13px;
                margin: 0;
            }
        }
    }
}

div.modal {
    .modal-body {
        overflow: auto;
    }
}

